@import "colors";

//noinspection CssOverwrittenProperties
* {
    box-sizing: border-box;
    margin: 0;
    font-family: Scientifica, "Comic Code Ligatures", "Comic Code", "JetBrains Mono", monospace;
    line-height: 1.4;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

html {
    min-height: 100%;
    position: relative;
}
body {
    height: 100%;
}
html, body {
    background: $background;
    color: $greener;
    font-size: 18px;
}

a, a:visited {
    color: $greener;
}
a:hover, a:focus {
    color: $greenest;
}

.container {
    margin: 0 auto;
    max-width: 900px;
    min-height: 100%;
    border: 1px solid $background-sat;
}

.header {
    min-height: 40px;
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0 15px;
    border-bottom: 2px solid $background-sat;

    > li {
        // ugly hack to center the text optically
        transform: translateY(2px);
        margin-right: 15px;
    }
    > .skip {
        position: absolute;
        top: -9999px;

        &:focus-within {
            position: static;
        }
    }

    &.rt-controls {
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;

    .checkbox-root {
        width: 1em;
        height: 1em;
        margin-right: 8px;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            opacity: 0;
        }

        span {
            display: inline-block;
            width: 1em;
            height: 1em;
            border: 3px solid $greener;
        }

        input:checked ~ span {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAFklEQVQImWNgwAk2i/xHoTEkCKtEAgDMFAjfa1xt8wAAAABJRU5ErkJggg==");
            background-size: 100%;
            image-rendering: pixelated;
        }
    }
}

.me {
    display: flex;
    padding: 10px;

    > .image {
        width: auto;
        height: 100px;
        margin-right: 20px;
    }

    > .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        * {
            text-align: center;
        }

        .welcome {
            font-size: 20px;
            margin-bottom: -10px;
        }

        .sparkles {
            width: 40px;
            height: 40px;
            margin: 0 15px -5px;

            @media screen and (max-width: 450px) {
                display: none;
            }
        }

        h1 {
            font-size: 50px;
        }
    }
}

.content {
    padding: 10px 10px 10px;
    font-size: 25px;
    position: relative;
    margin-top: 15px;
    border: 2px solid $greener;

    > *:not(:last-child) {
        margin-bottom: 10px;
    }

    .message-thingy {
        width: 32px;
        height: 32px;
        position: absolute;
        top: -16px;
        left: 40px;
    }

    .map {
        float: right;
        width: 2 * 128px;
        height: 2 * 106px;
        margin-left: 10px;

        @media screen and (max-width: 550px) {
            width: 128px;
            height: 106px;
        }
    }

    .clear {
        clear: both;
    }

    ul {
        list-style: square;
    }

    .rust {
        .r {
            color: #f85050;
        }
        .u {
            color: #f8f823;
        }
        .s {
            color: #31e0e0;
        }
        .t {
            color: #926fda;
        }
    }
}

.bigass-row {
    display: flex;

    .online-status {
        padding: 10px;
        font-size: 24px;
        border-right: 1px solid $background-sat;

        .offline {
            color: $redder;
        }
    }
}

.cols {
    display: flex;
    border-top: 1px solid $background-sat;
    min-height: 300px;

    > * {
        width: 100%;
        flex-basis: 50%;
        padding: 10px;
    }
    > :first-child {
        border-right: 1px solid $background-sat;
    }

    .flash {
        width: 24px;
        height: 24px;
        position: relative;
        top: 3px;
    }

    ul {
        font-size: 20px;
        list-style-type: none;
        padding-left: 0;
    }
}

// pong

.pong {
    margin-bottom: 100px;
    height: 330px;
    width: 900px;
    position: relative;

    @media screen and (max-width: 900px) {
        display: none;
    }

    .pong-controls {
        height: 30px;
        padding: 0 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        input {
            background: transparent;
            border: 1px solid $greener;
            color: $greener;
            font-size: 18px;
            max-width: 10em;

            &:focus-within {
                outline: 1px $greenest;
            }
        }

        .ping-display {
            display: inline-block;
            min-width: 2em;
            text-align: right;
        }

        .bot-button {
            background: transparent;
            color: $greenest;
            border: 1px solid $greener;
            font-size: 18px;
            cursor: pointer;

            &:hover, &:focus {
                background: $background-sat;
            }
        }
    }

    .pong-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 300px;
        width: 900px;

        .pong-divider {
            position: absolute;
            width: 1px;
            height: calc(100% - 40px);
            left: 50%;
            top: 40px;
            background: $background-sat;
        }

        .pong-circle {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid $background-sat;
            border-radius: 100%;
        }

        .pong-text {
            position: absolute;
            font-size: 40px;
            opacity: 0.5;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
        }

        .pong-score {
            position: absolute;
            font-size: 30px;
            opacity: 0.5;
            top: 0;
            left: 2px;
            width: 100%;
            text-align: center;
        }
    }

    .pong-game {
        border-top: 1px solid $background-sat;
        border-bottom: 1px solid $background-sat;
        height: 300px;
        width: 900px;

        position: relative;
    }

    .paddle {
        background: $greener;
        width: 3px;
        position: absolute;
        pointer-events: none;
    }

    .ball {
        background: $greener;
        width: 5px;
        height: 5px;
        position: absolute;
        pointer-events: none;
    }
}

// etc

.inline {
    cursor: text;
}

#cursors {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;

    > .cursor {
        position: absolute;
        width: 64px;
        height: 64px;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: 50%;

        &.t1 {
            background-image: url("/cursors/default.png");
            background-position: 5px 7px;
        }
        &.t2 {
            background-image: url("/cursors/handpointing.png");
            background-position: 4px 8px;
        }
        &.t3 {
            background-image: url("/cursors/textcursor.png");
        }
    }
}

// animations

.blink {
    text-shadow: $greener 0 0 20px, $greener 0 0 50px;
}
.blink.red {
    text-shadow: $redder 0 0 20px, $redder 0 0 50px;
}

.inline-swing {
    display: inline-block;
    animation: 1s ease-in-out infinite swing;
}

@keyframes swing {
    from {
        transform: rotate(8deg);
    }
    50% {
        transform: rotate(-8deg);
    }
    to {
        transform: rotate(8deg);
    }
}
