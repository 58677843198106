@font-face {
  font-family: Scientifica;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("scientifica.f1838a00.woff2");
}

* {
  box-sizing: border-box;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  margin: 0;
  font-family: Scientifica, Comic Code Ligatures, Comic Code, JetBrains Mono, monospace;
  line-height: 1.4;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  height: 100%;
}

html, body {
  color: #00b314;
  background: #001203;
  font-size: 18px;
}

a, a:visited {
  color: #00b314;
}

a:hover, a:focus {
  color: #00cc16;
}

.container {
  border: 1px solid #004708;
  max-width: 900px;
  min-height: 100%;
  margin: 0 auto;
}

.header {
  border-bottom: 2px solid #004708;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  padding: 0 15px;
  font-size: 20px;
  list-style: none;
  display: flex;
}

.header > li {
  margin-right: 15px;
  transform: translateY(2px);
}

.header > .skip {
  position: absolute;
  top: -9999px;
}

.header > .skip:focus-within {
  position: static;
}

@media screen and (width <= 900px) {
  .header.rt-controls {
    display: none;
  }
}

.checkbox-label {
  cursor: pointer;
  align-items: center;
  margin-right: 16px;
  display: flex;
}

.checkbox-label .checkbox-root {
  width: 1em;
  height: 1em;
  margin-right: 8px;
}

.checkbox-label .checkbox-root input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-label .checkbox-root span {
  border: 3px solid #00b314;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.checkbox-label .checkbox-root input:checked ~ span {
  image-rendering: pixelated;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAFklEQVQImWNgwAk2i/xHoTEkCKtEAgDMFAjfa1xt8wAAAABJRU5ErkJggg==");
  background-size: 100%;
}

.me {
  padding: 10px;
  display: flex;
}

.me > .image {
  width: auto;
  height: 100px;
  margin-right: 20px;
}

.me > .right {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.me > .right * {
  text-align: center;
}

.me > .right .welcome {
  margin-bottom: -10px;
  font-size: 20px;
}

.me > .right .sparkles {
  width: 40px;
  height: 40px;
  margin: 0 15px -5px;
}

@media screen and (width <= 450px) {
  .me > .right .sparkles {
    display: none;
  }
}

.me > .right h1 {
  font-size: 50px;
}

.content {
  border: 2px solid #00b314;
  margin-top: 15px;
  padding: 10px;
  font-size: 25px;
  position: relative;
}

.content > :not(:last-child) {
  margin-bottom: 10px;
}

.content .message-thingy {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -16px;
  left: 40px;
}

.content .map {
  float: right;
  width: 256px;
  height: 212px;
  margin-left: 10px;
}

@media screen and (width <= 550px) {
  .content .map {
    width: 128px;
    height: 106px;
  }
}

.content .clear {
  clear: both;
}

.content ul {
  list-style: square;
}

.content .rust .r {
  color: #f85050;
}

.content .rust .u {
  color: #f8f823;
}

.content .rust .s {
  color: #31e0e0;
}

.content .rust .t {
  color: #926fda;
}

.bigass-row {
  display: flex;
}

.bigass-row .online-status {
  border-right: 1px solid #004708;
  padding: 10px;
  font-size: 24px;
}

.bigass-row .online-status .offline {
  color: #c21717;
}

.cols {
  border-top: 1px solid #004708;
  min-height: 300px;
  display: flex;
}

.cols > * {
  flex-basis: 50%;
  width: 100%;
  padding: 10px;
}

.cols > :first-child {
  border-right: 1px solid #004708;
}

.cols .flash {
  width: 24px;
  height: 24px;
  position: relative;
  top: 3px;
}

.cols ul {
  padding-left: 0;
  font-size: 20px;
  list-style-type: none;
}

.pong {
  width: 900px;
  height: 330px;
  margin-bottom: 100px;
  position: relative;
}

@media screen and (width <= 900px) {
  .pong {
    display: none;
  }
}

.pong .pong-controls {
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  display: flex;
}

.pong .pong-controls input {
  color: #00b314;
  background: none;
  border: 1px solid #00b314;
  max-width: 10em;
  font-size: 18px;
}

.pong .pong-controls input:focus-within {
  outline: 1px #00cc16;
}

.pong .pong-controls .ping-display {
  text-align: right;
  min-width: 2em;
  display: inline-block;
}

.pong .pong-controls .bot-button {
  color: #00cc16;
  cursor: pointer;
  background: none;
  border: 1px solid #00b314;
  font-size: 18px;
}

.pong .pong-controls .bot-button:hover, .pong .pong-controls .bot-button:focus {
  background: #004708;
}

.pong .pong-overlay {
  width: 900px;
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pong .pong-overlay .pong-divider {
  background: #004708;
  width: 1px;
  height: calc(100% - 40px);
  position: absolute;
  top: 40px;
  left: 50%;
}

.pong .pong-overlay .pong-circle {
  border: 1px solid #004708;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pong .pong-overlay .pong-text {
  opacity: .5;
  text-align: center;
  width: 100%;
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.pong .pong-overlay .pong-score {
  opacity: .5;
  text-align: center;
  width: 100%;
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 2px;
}

.pong .pong-game {
  border-top: 1px solid #004708;
  border-bottom: 1px solid #004708;
  width: 900px;
  height: 300px;
  position: relative;
}

.pong .paddle {
  pointer-events: none;
  background: #00b314;
  width: 3px;
  position: absolute;
}

.pong .ball {
  pointer-events: none;
  background: #00b314;
  width: 5px;
  height: 5px;
  position: absolute;
}

.inline {
  cursor: text;
}

#cursors {
  pointer-events: none;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#cursors > .cursor {
  background-repeat: no-repeat;
  background-size: 50%;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
}

#cursors > .cursor.t1 {
  background-image: url("default.01dd036b.png");
  background-position: 5px 7px;
}

#cursors > .cursor.t2 {
  background-image: url("handpointing.010fa5f0.png");
  background-position: 4px 8px;
}

#cursors > .cursor.t3 {
  background-image: url("textcursor.64d809f0.png");
}

.blink {
  text-shadow: 0 0 20px #00b314, 0 0 50px #00b314;
}

.blink.red {
  text-shadow: 0 0 20px #c21717, 0 0 50px #c21717;
}

.inline-swing {
  animation: 1s ease-in-out infinite swing;
  display: inline-block;
}

@keyframes swing {
  from {
    transform: rotate(8deg);
  }

  50% {
    transform: rotate(-8deg);
  }

  to {
    transform: rotate(8deg);
  }
}

/*# sourceMappingURL=index.ccc50808.css.map */
