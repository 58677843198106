$background: #001203;
$background-sat: #004708;
$greener: #00b314;
$greenest: #00cc16;
$redder: #c21717;

@font-face {
  font-family: "Scientifica";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/scientifica.woff2");
}
